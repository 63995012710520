.header
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center
  border-bottom: 2px solid #e9e7e6

.header__logo 
  margin-left: 26px
  display: -webkit-box
  display: -ms-flexbox
  display: flex

.header__links 
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center
  margin-left: auto

.header__links .link
  // margin-right: 10px
  color: #a29e9c
  font-size: 13px
  line-height: 0.85

.header__links .link:last-child 
  margin-left: 24px
  background-color: #6e7992
  padding: 8px 12px 8px 5px
  color: #d6d6d6
  border-radius: 2px

