.footer 
    background: #424040
    color: #ffffff

.footer__wrapper
  display: flex
  align-items: flex-end
  padding: 56px 0 80px 0
  line-height: 16px

.footer__copyright
  margin: 0
  font-size: 13px

.footer__copyright-link
  display: flex
  flex-direction: column
  display: inline
  color: $white
  text-decoration: underline

.footer__policy
  display: block
  margin-top: 20px
  text-decoration: underline
  color: $white

.footer__content
    font-size: 13px
    color: #fff

.copyright__link, .rules__link, .private__link
    color: #fff
    display: inline-block
    margin-right: 10px

.footer__rules
    display: flex
    margin-top: 10px

.footer__logo
    margin-left: auto
