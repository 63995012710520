.about
  padding-top: 27px
  padding-bottom: 100px
  background-color: #F6F6F6

.about__desc
  width: 525px
  font-size: 18px
  font-weight: 300
  line-height: 26px
  color: #435466

.about__wrapper
  display: flex
  justify-content: space-between

.about__list
  width: 470px
  margin-top: 65px

.about__elem
  margin-bottom: 25px

.about__elem-title
  margin: 0
  font-size: 16px
  font-weight: 400
  line-height: 26px
  text-transform: uppercase
  color: $dark-red

.about__elem-link
  color: $blue
  transition: 0.3s

  &:hover
    color: $red

.about__elem-desc
  margin: 0
  margin-top: 11px
  font-size: 18px
  font-weight: 300
  line-height: 26px

.about__pic
  width: 578px
  object-fit: contain

.about__access-text
  width: 775px
  margin: 88px auto 0
  text-align: center
  font-size: 35px
  font-weight: 300
  line-height: 43px

  &--excel  
    width: 600px

.about__button
  display: flex
  justify-content: center
  align-items: center
  width: 378px
  height: 45px
  margin: 30px auto 0
  font-size: 18px
  font-weight: 300
  color: #ffffff
  background-color: $dark-red
  border-radius: 3px
