.section__form
    position: relative
    z-index: 101
    background-color: #F6F6F6
    // display: none

    &--kp
        top: -175px
        margin-bottom: -175px
        background-color: transparent

    .crmresult
        height: auto

.section__form-wrapper
    position: relative
    top: -100px
    min-height: 163px
    margin-bottom: -100px
    padding: 38px 10px 20px
    background-color: white
    border: solid 1px $light-gray
    border-left: none
    border-right: none
    border-radius: 3px

    &::after
        content: ''
        position: absolute
        top: -1px
        right: -53px
        width: 54px
        height: 100%
        background-color: white
        border: 1px solid $light-gray
        border-left: 0
        border-radius: 3px
        border-top-left-radius: 0

    &::before
        content: ''
        position: absolute
        top: -1px
        left: -53px
        width: 54px
        height: 100%
        background-color: white
        border: 1px solid $light-gray
        border-right: 0
        border-radius: 3px
        border-top-right-radius: 0
    
    &--otchet
        top: 0
        margin-bottom: 0

    &--fsbu
        top: -50px
        margin-bottom: -50px
    // &--kp
    //     position: static
    //     padding: 0
    //     margin: 0
    //     min-height: auto
    //     background-color: transparent
    //     border: none

    //     &::after,
    //     &::before
    //         display: none
            
    //     .presale__form-label
    //         color: transparent


// .form-list
//     position: relative
//     display: grid
//     grid-template-columns: repeat(3, 1fr)
//     grid-column-gap: 20px
//     margin-top: 20px
//     padding: 20px 10px 20px
//     background-color: white
//     border: solid 1px $light-gray
//     border-left: none
//     border-right: none
//     border-radius: 3px
//     box-shadow: 0px 2px 10px rgba(112, 116, 124, 0.06)
//     display: none

//     &::after
//         content: ''
//         position: absolute
//         top: -1px
//         right: -53px
//         width: 54px
//         height: 100%
//         background-color: white
//         border: 1px solid $light-gray
//         border-right: 0
//         border-radius: 3px
//         border-top-left-radius: 0

//     &::before
//         content: ''
//         position: absolute
//         top: -1px
//         left: -53px
//         width: 54px
//         height: 100%
//         background-color: white
//         border: 1px solid $light-gray
//         // border-right: 0
//         border-radius: 3px
//         border-top-right-radius: 0

// .form-list__title
//     position: relative
//     display: flex
//     align-items: center
//     margin: 0
//     min-height: 70px
//     padding-left: 70px
//     font-size: 43px
//     font-weight: 400
//     color: #F1361D

//     &::before
//         content: ''
//         position: absolute
//         top: 50%
//         transform: translateY(-50%)
//         left: 0
//         width:  60px
//         height: 60px
//         background-size: contain
//         background-repeat: no-repeat
//         background-position: center

//     &--hr
//         &::before
//             background-image: url('../img/icon/icon-hr.svg')

//     &--lawyer
//         &::before
//             background-image: url('../img/icon/icon-lawyer.svg')

//     &--tax
//         &::before
//             background-image: url('../img/icon/icon-tax.svg')

// .form-list__desc
//     margin-top: 10px
//     font-size: 18px
//     line-height: 24px
        

// .section__form-desc
//     font-size: 16px
//     color: $dark-red
//     text-transform: uppercase

.form
    display: flex
    justify-content: space-between
    align-items: flex-end
    margin-top: 15px

.presale__form-label
    width: calc(100% /4)
    margin-left: 6px
    font-size: 14px
    font-weight: 500
    line-height: 17px
    text-transform: uppercase
    color: $dark-gray
    
    &:first-of-type
        margin-left: 0

    &:last-of-type
        margin-right: 21px

.presale__form-input
    display: block
    width: 100%
    height: 46px
    margin-top: 10px
    padding: 0 17px 0 15px 
    font-size: 16px
    line-height: 19px
    color: black
    border: solid 1px $light-gray
    border-radius: 3px
    box-sizing: border-box
    outline: none

    &:focus
        border: solid .5px $dark-gray
        transition: 0.3s

.presale__form-btn
    width: 260px
    height: 46px
    padding: 0
    font-size: 18px
    line-height: 22px
    color: white
    background-color: $dark-red
    border-radius: 3px
    outline: none
    transition: 0.3s

    &:focus,
    &:hover
        background-color:#e41d35

.form__agreement
    margin-top: 17px
    margin-left: auto
    font-size: 12px
    color: $dark-gray

    &--kp
        margin-top: 5px
        text-align: right
        color: #ffffff

.crmresult 
    justify-content: center
    align-items: center
    min-height: 86px
    font-size: 18px
    font-weight: 300
    line-height: 27px
    text-align: center
    color: #2F3441
    background: #ffffff url('../../img/crmresult.svg') no-repeat left center
    