.hero
  position: relative
  background: #ffffff url('../img/background/hero_bg.png') no-repeat center right
  background-size: contain
  color: black
  overflow: hidden

  &--kp
    color: #ffffff
    background: radial-gradient(92.13% 90.13% at 50% 50%, #FF4F6F 0%, #F33040 100%)
    background-size: cover
    overflow: hidden
    z-index: 0

    &::after
      content: ''
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      width: 100%
      height: 100%
      background: center / cover no-repeat url('../img/hero_bg-kp.svg') 
      z-index: -2


    .hero__header
      display: flex
      justify-content: space-between
      align-items: center
    
    .hero__header-phone
      display: block
      margin-left: 0
      // display: none


    @media (max-width: 1980px)
      &::before
        right: -100px



  // @media (max-width: 1980px)
  //   &::before
  //     bottom: 0
  //     top: auto
  //     width: 68%

  &--excel
    background-image: url('../img/background/hero_bg-excel.png')

  &--calendar
    background-image: url('../img/background/hero_bg-calendar.svg')

  &--nds
    background-image: url('../img/background/hero_bg-nds.svg')

  &--otchet
    background-image: url('../img/background/hero_bg-otchet.svg')
    background-size: contain
    background-position: 70% 0

  &--ifns
    background-image: url('../img/background/hero_bg-ifns.svg')
    
  &--fsbu
    background-image: url('../img/background/hero_bg-fsbu.png')
    background-size: cover

  &--banks
    background-image: url('../img/background/hero_bg-banks.svg')
    background-position: 74% 30px
    background-size: 650px

  &--otchetnost
    background-image: url('../img/background/hero_bg-otchetnost.svg')

  &--enp
    background-image: url('../img/background/hero_bg-enp.svg')

  &--2024
    background-image: url('../img/background/hero_bg-2024.svg')
    background-position: 80% 0

  &--holiday
    background-image: url('../img/background/hero_bg-holiday.svg')
    background-position: 75% 0

  &--ndfl
    background-image: url('../img/background/hero_bg-ndfl.svg')
    background-position: 75% 0

  &--summer24
    background-image: url("../img/background/hero_bg-summer2024.svg")

  &--reforma
    background-image: url('../img/background/hero_bg-reforma.svg')
    background-position: 80% 100%

  &--price
    background: url('../img/background/bg-price.svg') no-repeat center
    background-size: cover

.hero__header
  display: flex
  align-items: flex-end
  padding-top: 32px

.hero__header-link
  line-height: 0
  width: 220px
  position: relative

.hero__header-logo
  width: 100%
  height: 100%

.hero__header-phone
  margin: 0
  margin-left: auto
  font-size: 24px
  line-height: 29px
  text-align: right
  letter-spacing: 0.27px
  color: $white
  z-index: 8
  display: none
  // opacity: 0

  span
    display: block
    font-size: 13px
    line-height: 16px

.hero__header-phone__link
  color: $white

// .hero__content-wrapper
  // width: 420px
  // padding: 43px 11px 41px 30px
  // background: rgba(255, 255, 255, 0.18)
  // box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.09)
  // backdrop-filter: blur(4.5px)
  // border-radius: 20px

  // &--kp
  //   position: relative
  //   width: 644px
  //   padding: 90px 72px
  //   box-shadow: none
  //   background-color: transparent


.hero__content
  position: relative
  margin-top: 53px
  padding-bottom: 120px
  color: #ffffff
  z-index: 1

  &--kp
    position: relative
    padding-top: 37px
    padding-bottom: 142px

  &--price
    margin-top: 30px
    padding-bottom: 327px

    

    // &::after
    //   content: ''
    //   position: absolute
    //   bottom: 80px
    //   right: -200px
    //   width: 300px
    //   height: 400px
    //   background: url('../img/present-icon.png') no-repeat center bottom
    //   background-size: contain
    //   filter: drop-shadow(17px 4px 20.1px rgba(0, 0, 0, 0.25))
    //   z-index: 9

  &--otchet
    padding-bottom: 60px

  &--fsbu
    margin-top: 80px

  

.hero__caption
  margin: 0
  margin-top: 28px
  font-size: 30px

  &--kp
    display: inline-block
    margin: 0
    margin-top: 20px
    line-height: 0
    // font-size: 28px
    // line-height: 1
    // font-weight: 600
    // padding: 8px 25px
    // text-transform: uppercase
    // color: #000000
    // background: url('../img/caption-bg.svg') no-repeat center
    // background-color: #ffffff
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.18)
    // border-radius: 10px
    // background-size: contain
    display: none

.hero__title
  position: relative
  width: 450px
  margin: 0
  font-size: 40px
  line-height: 41px
  font-weight: 600
  text-align: left
  color: #E50000

  span
    font-size: 52px
    font-weight: 400

  &--excel,
  &--calendar,
  &--nds,
  &--otchet,
  &--ifns,
  &--fsbu,
  &--banks,
  &--otchetnost,
  &--enp,
  &--holiday,
  &--summer24,
  &--reforma
    width: 550px
    font-size: 50px
    font-weight: 700
    line-height: 48px
    color: black

    span
      font-weight: 700
      color: #387852
    
  &--kp
    position: relative
    width: 780px
    margin: 0
    font-size: 76px
    font-weight: 700
    text-transform: uppercase
    line-height: 1
    color: #ffffff

    span
      display: flex
      flex-direction: column
      margin-top: 18px
      font-size: 44px
      font-weight: 600
      line-height: 0.5
      text-transform: none

      span
        display: flex
        align-self: flex-start
        align-items: flex-start
        padding: 12px 10px
        color: #F84731
        background-color: #ffffff
        border-radius: 40px

  &--fsbu
    font-size: 60px
    color: $white

  &--banks
    font-size: 48px

  &--price
    width: 550px
    font-size: 62px
    font-weight: 800
    line-height: 63px
    color: #ffffff

.hero__desc
  position: relative
  width: 480px
  margin: 0
  margin-top: 15px
  font-size: 23px
  line-height: 28px
  color: #000000

  &::before
    content: ''
    position: absolute
    right: -70px
    bottom: -30px
    width: 157px
    height: 174px
    background: url('../img/x2.png') no-repeat center
    background-size: cover
    z-index: -1
    display: none

  &--kp
    position: relative
    width: 730px
    margin: 60px 0 0 0
    // font-family: 'Molot'
    font-size: 24px
    font-weight: 600
    line-height: 33px
    color: #ffffff
    z-index: 9


    span
      display: inline-block
      font-weight: 600
      line-height: 1.3
      border-radius: 5px

  &--otchet,
  &--summer24
    width: 560px

  &--reforma
    font-size: 20px
    line-height: 28px

  &--reforma
    width: 515px

  &--price
    width: 430px
    color: #ffffff

.hero__price
  display: flex
  align-items: center
  font-size: 40px

.hero__old-price
  position: relative
  margin: 0
  margin-left: 10px
  font-size: 30px
  line-height: 24px

  &:before
    content: ''
    position: absolute
    top: 50%
    transform: translate(-10px) rotate(-15deg)
    width: 100px
    height: 1px
    background-color: $white
    transition: 0.3s

.hero__new-price
  margin: 0
  margin-left: 30px
  font-size: 50px
  line-height: 50px

.hero__sale
  position: absolute
  top: 50%
  right: 50px
  transform: translateY(-50%)

.hero__button
  position: relative
  display: flex
  justify-content: center
  align-items: center
  width: 408px
  height: 45px
  margin-top: 32px
  padding: 0
  font-family: inherit
  font-size: 18px
  line-height: 21px
  color: $white
  background: $blue
  border: none
  border-radius: 4px
  transition: 0.4s

  &--kp
    position: relative
    display: flex
    justify-content: center
    align-items: center
    width: 550px
    height: 75px
    margin: 40px 0 0 0
    font-size: 27px
    line-height: 21px
    color: #ffffff
    box-shadow: 8px 8px 5px rgba(0, 0, 0, 0.2)
    border: none
    border-radius: 200px
    transition: 0.4s
    z-index: 99
    background: #FCEE21
    color: #000000
    box-shadow: 6.366px 6.366px 5.411px 0px rgba(0, 0, 0, 0.15) inset


  &--fsbu
    width: 370px

.hero__button--red
  color: #ffffff
  background-color: #D0021B

  &:hover
    background-color: #e41d35

.hero__content-image
  position: absolute
  top: -190px
  right: -550px
  width: 1367px
  height: 100%
  object-fit: contain
  z-index: 1

  &--price
    width: 807px
    top: -150px
    right: -200px

.hero__content-pic
  width: 100%
  height: 100%
  object-fit: contain

.hero__present
  position: relative
  width: 410px
  margin-top: 50px
  padding-left: 50px
  color: #000

  &::before
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 32px
    height: 32px
    background: url('../img/icon/icon-present.svg') no-repeat center
    background-size: contain

.hero__list
  position: absolute
  right: 0
  top: -70px
  display: flex
  flex-direction: column
  align-items: center

.hero__list-item
  position: relative

  &:nth-child(2)
    top: -50px

  &:last-of-type
    position: relative
    top: -100px
    animation: buttonScale 2s linear infinite 

    &:before
      content: ''
      width: 80%
      height: 80%
      position: absolute
      top: 5px
      left: 6px
      border: 1px solid #ffffff
      border-radius: 25px
      transform: rotate(11.5deg)
      animation: trfbtn 2s linear infinite

.promo__note
  width: 580px
  margin-top: 10px
  font-size: 12px
  line-height: 16px
  color: rgba(255, 255, 255, 0.67)


// Price
.hero-calculation-form__label
  display: flex
  width: 755px
  height: 50px
  margin-top: 50px
  background-color: #ffffff
  border-radius: 3px
  overflow: hidden

  input
    height: 100%
    width: 100%
    padding-left: 20px
    font-size: 20px
    font-weight: 300
    border: none
    outline: none

.hero-calculation-form__btn
  display: flex
  align-items: center
  justify-content: center
  flex: 1 0 auto
  width: 307px
  height: 100%
  padding: 0
  font-size: 21px
  font-weight: 600
  color: #ffffff
  background-color: #237CF9
  border-top-left-radius: 3px
  border-bottom-left-radius: 5px
  outline: none

  &:disabled
    background-color: #5a5a5a