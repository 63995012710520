.purchase
  padding-bottom: 30px
  background-color: #ffffff

.purchase-list
  display: grid
  grid-template-columns: repeat(3, 1fr)
  margin-top: 20px

.purchase__elem
  margin: 10px 0

.purchase__elem-title
  margin: 0 0 5px 0

.purchase__elem-adress
  font-style: normal

.purchase__elem-phone, .purchase__elem-mail
  margin-top: 5px