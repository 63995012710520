.section__advice
  background-color: #fff
  
.advice
  display: flex
  justify-content: space-between
  margin-top: 56px

.advice__content
  width: 465px
  
.advice__list
  margin-top: 76px

.advice__list-item
  margin-bottom: 25px

  &:last-child
    margin-bottom: 0

.advice__list-title 
  margin: 0
  font-size: 22px
  font-weight: 600
  line-height: 26px
  color: $dark-red

.advice__list-desc
  margin: 0
  font-size: 18px
  font-weight: 300
  line-height: 26px


.advice__button
  margin-top: 27px
  display: flex

  .button
    display: block
    width: 378px
    padding: 11.5px 0
    font-size: 18px
    font-weight: 300
    line-height: 1.44
    text-align: center
    background-color: $dark-red
    color: #fff
    border-radius: 3px
    transition: 0.3s
    outline: none
    &:hover,
    &:focus
      background-color: #e41d35
      outline: none

.advice__pic
  position: relative
  width: 600px
  margin: 0 0 82px 75px
  line-height: 0

.advice__pic-quest
  width: 350px
  margin-top: 34px
  img
    max-width: 100%

.advice__pic-anim
  position: absolute
  top: 0
  right: 0
  width: 327px
  img
    max-width: 100%
