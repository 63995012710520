.insert__title
    text-align: center
    font-size: 35px
    color: $gray2
    span
        display: block

.insert__desc 
    margin: 0  
    padding-bottom: 11px
    font-size: 21px
    font-weight: 500
    text-transform: uppercase
    color: $gray2
    span
        display: block

.insert__line
    width: 100%
    height: 1px
    background-color: #e5e5e5

.insert--first
    padding-top: 40px
    background-color: $gray8

.insert--second
    .insert   
        padding-top: 59px

.insert--white
    background-color: #ffffff

