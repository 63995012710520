.section-order
  background-color: #fff
  
  &--price
    background-color: rgba(247, 245, 243, 1)

.order
  position: relative
  display: flex
  justify-content: space-between
  padding-top: 43px
  margin-bottom: 104px
  background: url('../img/order-bg.png') no-repeat center right, linear-gradient(96.84deg, #F96767 22.58%, #F81F02 74.45%)
  background-size: cover
  border-top: 1px solid $light-gray
  border-bottom: 1px solid $light-gray

  &::before
    content: ''
    position: absolute
    top: -1px
    left: -55px
    width: 54px
    height: 100%
    background: #F96767
    background-size: cover
    border: 1px solid $light-gray
    border-right: 0
    border-radius: 3px 0 0 3px
  &::after
    content: ''
    position: absolute
    top: -1px
    right: -54px
    width: 54px
    height: 100%
    background: linear-gradient(to bottom, #F93102, #F71F02)
    background-size: cover
    border: 1px solid $light-gray
    border-left: 0
    border-radius: 0 3px 3px 0

  &--kp
    background: #F6F6F6
    border: 1px solid #E6E6E6
    border-width: 1px 0

    &::before,
    &::after
      background: #F6F6F6

  &--excel
    background: linear-gradient(180deg, #2A764C 0%, #19A357 72.97%)

    &::before,
    &::after
      background: linear-gradient(180deg, #2A764C 0%, #19A357 72.97%)
  

  &--holiday
    padding-right: 50px
    padding-left: 50px
    margin: 0 -50px
    background: #F6F6F6 url(../img/order_bg-holiday.svg) no-repeat center bottom

    &::before,
    &::after
      display: none
      background: #F6F6F6

  &--price
    align-items: center
    background: transparent
    border: none

    &::before,
    &::after
      display: none
    
.order__content
  color: #ffffff
  width: 545px
  
.order__title
  margin: 0
  margin-top: 20px
  font-size: 82px
  font-weight: 900
  line-height: 85%
  span
    font-size: 52px
    font-weight: 400

  &--nds
    font-size: 80px

  &--otchet
    font-size: 65px

  &--kp
    display: none

  &--holiday
    margin-top: 40px
    font-size: 40px
    line-height: 38px
    font-weight: 600
    color: #000

.order__desc
  width: 400px
  min-width: 525px
  margin-top: 30px
  font-size: 26px
  font-weight: 300
  line-height: 40px
  color: #ffffff

  span
    display: block
    font-weight: 500

  &--kp
    font-size: 35px
    line-height: 54px
    color: #2D3039
    span
      display: inline
      color: #D0021B

  &--holiday
    font-size: 24px
    line-height: 35px
    color: #000

  &--price
    color: #000000
    
    strong
      display: block

.order-form
  display: flex
  flex-direction: column
  width: 558px
  padding: 56px 71px 28px 70px
  background-color: white

.order-input
  width: 100%
  margin-bottom: 10px
  padding-bottom: 13px
  font-size: 18px
  line-height: 24px
  color: #2f3441
  border: none
  border-bottom: 1px solid #979797
  border-radius: 0

  &:focus
    border-bottom-color: #000
    outline: none

  &:last-of-type
    margin-bottom: 40px

.order__form-button
  width: 100%
  height: 50px
  font-size: 21px
  font-weight: 300
  line-height: 26px
  color: white
  background-color: #0074EB
  transition: 0.3s
  &:hover,
  &:focus
    background-color: #2f8dec
    outline: none

.form__agreement-order
  margin-top: 14px
  font-size: 14px
  line-height: 23px

.crmresult 
  display: flex
  align-items: center
  height: 426px
  padding: 56px 71px 28px 70px
  margin-left: 47px
  font-size: 18px
  font-weight: 300
  line-height: 27px
  text-align: center
  color: #2F3441
  background: #ffffff url('../../img/crmresult.svg') no-repeat center 25%

