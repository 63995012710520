.features
  margin-top: 60px

.features__wrapper
  display: flex
  flex-direction: column

.features__title
  width: 514px
  margin: 0 auto

.features__list
  display: grid
  grid-template-columns: 320px 320px 320px
  column-gap: 39px
  margin: 0 auto 0 auto
  padding-bottom: 53px

.features__elem
  padding-right: 42px
  border-right: 1px solid #D1D1D1

  &:nth-child(n + 4)
    padding-top: 20px

    .features__elem-wrapper
      border-bottom: none
      min-height: 115px

  &:nth-child(3n)
    border-right: none
    

.features__elem-wrapper
  min-height: 140px
  border-bottom: 1px solid #D1D1D1

.features__elem-title
  position: relative
  margin: 0
  padding-left: 34px
  font-size: 20px
  line-height: 27px
  color: $dark-red

  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 26px
    height: 26px
    background: no-repeat center
    background-size: contain
  &--play
    &::before
      background-image: url('../img/icon/formiobrazci.svg')

  &--conf
    &::before
      background-image: url('../img/icon/journal&book.svg')

  &--video
    &::before
      background-image: url('../img/icon/service.svg')

  &--discuss
    &::before
      background-image: url('../img/icon/spravochniki.svg')

  &--star
    &::before
      background-image: url('../img/icon/videoseminari.svg')

  &--bookmark
    &::before
      background-image: url('../img/icon/meetup.svg')

  &--seminar
    &::before
      background-image: url('../img/icon/icon-seminar.svg')

  &--desktop
    &::before
      background-image: url('../img/icon/icon-desktop.svg')

  &--school
    &::before
      background-image: url('../img/icon/icon-school.svg')

  &--gift
    &::before
      background-image: url('../img/icon/icon-gift.svg')

  &--email
    &::before
      background-image: url('../img/icon/icon-email.svg')

.features__elem-desc
  margin: 0
  margin-top: 20px
  font-size: 16px
  line-height: 22px
  color: #2D3039

.features__desc
  width: 820px
  margin: 20px auto
  font-size: 35px
  color: #2D3039
  text-align: center
  line-height: 43px

.features__link
  display: flex
  justify-content: center
  align-items: center
  width: 420px
  height: 50px
  margin: 0 auto
  font-size: 21px
  color: #ffffff
  background-color: #0074EB
  border-radius: 3px
  transition: .3s

  &:hover
    background-color: #238ef8