.seminar
  background: linear-gradient(to right, rgba(18,2,0,100%), rgba(82,16,7,1))
  color: #ffffff
  display: none
  
.seminar__wrapper
  padding: 75px 0 143px 
  background: url('../img/seminar-bg.svg') no-repeat right bottom


.seminar__caption
  display: flex
  flex-wrap: wrap
  align-items: center
  font-size: 30px
  line-height: 59px

.seminar__date
  display: flex
  justify-content: center
  align-items: center
  width: 210px
  height: 43px
  margin-right: 32px
  padding: 0 18px
  font-size: 24px
  text-transform: uppercase
  color: #000
  background-color: #fff
  border-radius: 3px

.seminar__title
  width: 665px
  margin: 0
  margin-top: 114px
  font-size: 74px
  font-weight: 600
  line-height: 59px

.seminar__desc
  width: 505px
  margin-top: 10px
  font-size: 24px
  line-height: 37px

.seminar__link
  display: flex
  align-items: center
  justify-content: center
  width: 475px
  height: 58px
  margin-top: 32px
  font-size: 20px
  background-color: #F1361D
  color: #ffffff
  border-radius: 3px
  transition: 0.3s
  
  &:hover
    background-color: #ff2204