.scroll
    margin-top: 80px
    
.scroll__wrapper
    display: grid
    grid-template-columns: 500px 555px
    justify-content: space-between

.scroll__caption
    font-size: 21px
    font-weight: 600
    color: #2D3039
    text-transform: uppercase

.scroll__desc
    margin-top: 15px
    font-size: 18px
    font-weight: 300
    line-height: 26px

.scroll__list
    margin-top: 50px  

.scroll__item
    position: relative
    width: 470px
    margin-bottom: 40px
    cursor: pointer
    color: #435466

    &:last-of-type
        margin-bottom: 0

    &--active
        .scroll__item-caption
            color: #ffffff
            background-color: #D0021B
        
        .scroll__item-desc
            position: relative
            font-weight: 600
            font-size: 22px

            &::before
                content: ''
                position: absolute
                top: 15px
                right: -100px
                width: 80px
                height: 2px
                background-color: #D0021B

.scroll__item-caption
    display: inline-block
    padding: 5px 10px
    font-size: 13px
    font-weight: 300
    text-transform: uppercase
    color: #D0021B
    border: 1px solid #D0021B
    border-radius: 3px

.scroll__item-desc
    margin-top: 10px
    font-size: 18px
    line-height: 26px
    font-weight: 300

.scroll__image
    position: relative
    display: flex
    align-items: flex-start
    height: 965px
    overflow: hidden
    border: 1px solid #E5E5E5
    background-color: #ffffff

    &::before
        content: ''
        position: absolute
        top: 10px
        right: 0
        width: 210px
        height: 210px
        background: url('../img/rightbase.svg') no-repeat center
        background-size: cover
        z-index: 2


.scroll__pic
    object-fit: contain

    &--main
        transition: 0.5s
        width: 80%
    &--desc
        width: 20%