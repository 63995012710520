.promo
  margin-top: 50px
  padding-top: 48px
  background: #EB2027 url('../img/promo-bg.png') no-repeat center right
  background-size: contain

  @media (max-width: 767px)
    background-size: cover

.promo__wrapper
  color: #ffffff
  padding-bottom: 55px

.promo__logo:first-of-type
  vertical-align: middle

  @media (max-width: 767px)
    width: 140px

.promo__logo:last-of-type
  margin-left: 58px

  @media (max-width: 767px)
    display: block
    width: 200px
    margin-left: 0
    margin-top: 20px

.promo-content
  display: flex
  align-items: flex-start
  margin-top: 68px

  @media (max-width: 991px)
    flex-direction: column

.promo__logo-date
  width: 236px

  @media (max-width: 767px)
    width: auto
    margin: 0 auto

.promo__content-wrapper
  width: 760px
  margin-left: 88px

  @media (max-width: 991px)
    margin-left: 0
    margin-top: 30px

  @media (max-width: 767px)
    width: 100%

.promo__title
  margin: 0
  font-size: 60px
  line-height: 72px
  font-weight: 700

  @media (max-width: 767px)
    font-size: 36px
    line-height: 42px

.promo__desc
  margin: 0
  margin-top: 20px
  font-size: 24px
  line-height: 30px

  @media (max-width: 767px)
    font-size: 20px
    line-height: 26px

.promo__link
  display: flex
  justify-content: center
  align-items: center
  width: 425px
  height: 60px
  margin-top: 35px
  font-size: 22px
  background: #F9D03C
  border-radius: 3px
  transition: 0.3s
  
  &:hover
    background-color: #ffc800

  @media (max-width: 767px)
    width: 100%