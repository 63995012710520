@media screen and ( max-width:  1280px)
    .container
        padding: 0 10px
        max-width: 100%

    .hero--kp
        &::after
            display: none

        &::before
            display: none
        
    .hero__content-image
        width: 600px
        right: -50px
        z-index: -1
        top: -290px
        
    .hero__content-pic
        z-index: -1

    .hero__content
        &:before 
            display: none

    .section__form-wrapper, .section__form-list
        &::before,
        &::after
            display: none

    // scroll
    .scroll__wrapper
        grid-template-columns: repeat(2, 1fr)
        grid-column-gap: 50px

    .features__list
        grid-template-columns: repeat(3, 280px)

    .order
        align-items: flex-end
        &:after, 
        &:before
            display: none

        &--holiday
            align-items: center
            margin: 0

    .order__desc
        min-width: 450px
        width: 450px
    
    .order-form
        padding: 55px 50px 28px 50px
        margin-right: 10px
        margin-left: 20px

    
@media screen and ( max-width:  991px)
    .container
        padding: 0 5px

    // header
    .header__logo
        width: 100px
    
    // hero
    .hero
        &:before
            z-index: 0
            
        &::after
            display: none

        &--holiday
            background-position: 95% 55px

        &--reforma
            background-position: 80% 100%
            background-size: 50%

    .hero__title--kp
        width: auto
        margin: 0 auto
        margin-top: -10px

    .hero__content--kp
        width: auto
        padding: 0 0 400px 0 
        
        &::after
            display: none

    
    .hero__desc--kp
        width: auto
        margin: 20px auto 0

    .hero__button--kp
        margin: 30px 0 0 0

    .hero__content-image
        top: auto
        left: 0
        bottom: 10px
        width: 600px
        height: auto
        
    // form
    .section__form-wrapper
        margin: 0
        position: static 

        .form
            flex-wrap: wrap

            .presale__form-label
                width: calc( 100% / 3 - 10px)

                &:last-of-type
                    margin-right: 0 

            .presale__form-btn
                margin-left: auto
                margin-top: 15px

    .section__form-wrapper--kp
        .form
            display: grid
            grid-template-columns: repeat(1, 1fr)
            grid-column-gap: 10px
            .presale__form-label,.presale__form-btn
                width: 60%
                margin: 0 auto
                &:last-of-type
                    margin-right: auto
            .presale__form-btn
                margin-top: 20px

    .form__agreement--kp
        color: #474d5e
        text-align: center

    .form-list
        grid-template-columns: repeat(1, 1fr )
        grid-row-gap: 10px
        width: 60%
        margin: 0 auto

    .scroll
        display: none

    
    .about__access-text
        width: 740px
        font-size: 32px
    // advice
    .advice__pic
        margin-left: 40px

    // about
    .about__wrapper
        flex-direction: column

    // features
    
    .features__list
        grid-template-columns: repeat(2,320px)

    .features__elem
        padding-top: 20px
        &:nth-child(even)
            border-right: none
        &:nth-child(4)
            border-bottom: 1px solid #d1d1d1

    
    .features__desc
        width: auto
        font-size: 24px
        line-height: 100%

    .features__link
        width: 100%
            
    // order
    .order
        padding: 40px 10px 0 10px
        flex-wrap: wrap
        justify-content: center

    .order__desc
        width: auto
        font-size: 28px
        line-height: 50px

    .order-form
        width: 80%
        margin: 0
        margin-top: 20px

    .footer
        flex-direction: column

    .footer__content-right
        margin-top: 40px

    .footer__content-logo
        width: auto

@media screen and ( max-width:  767px)
    .container
        width: 95%

    // header
    .header
        padding: 0 0 0 10px

    .header__logo
        width: 80px
        display: none

    
    // hero
    .hero
        background-color: transparent

        &:before
            background-size: cover
            
    .hero--kp
        background: url('../img/hero-bg-kp-m.png'), radial-gradient(92.13% 90.13% at 50% 50%, #FF395E 0%, #BA000F 100%)
        background-repeat: no-repeat
        background-position: top right
        background-size: contain
        // padding-bottom: 350px

        &:before,
        &:after
            display: none

        .hero__header
            flex-direction: column
            align-items: start

            .hero__header-link:first-of-type
                .hero__header-logo
                    width: 100px

            .hero__header-phone
                display: none

    .hero__content--price
        padding-bottom: 450px    

        .hero__content-image
            width: auto

    .hero--ifns
        background-position: center 90%
        background-size: 60%

    .hero--summer24 
        background-position: center 90%
        background-size: 80%
    
    .hero__signup
        display: none

    .hero__caption
        font-size: 18px

    .hero__content
        margin-top: 28px

    .hero__content:not(.hero__content--kp)
        padding-bottom: 20px 

        &:before 
            bottom: 160px
            right: 0
            top: auto
            display: block
            width: 180px
            height: 180px
            background-size: cover
    
    .hero__content--kp
        padding-bottom: 440px
        margin: 0

        // &:after
        //     display: block
        //     right: -20px
        //     top: auto
        //     bottom: 170px
        //     width: 180px
        //     height: 180px

    .hero__title
        font-size: 34px
        line-height: 45px
        width: auto
        z-index: 99

        span   
            font-size: 32px

        img
            width: 100%
            position: static

        &--kp
            width: auto
            margin-top: 40px
            font-size: 32px
            line-height: 34px

            span
                margin-top: 15px
                font-size: 20px
                line-height: 5px

                span
                    margin-top: 10px
    .hero__desc
        width: auto
        margin-bottom: 40px
        font-size: 20px
        line-height: 28px
        z-index: 21

        &:before
            right: 0

        &--kp
            margin: 0
            margin-top: 50px
            width: 400px
            font-size: 18px
            line-height: 22px
            text-align: left

        &--price
            margin-bottom: 20px

    .hero__price
        display: none

    .hero__button
        width: 100%
        height: 46px
        margin-top: 120px
        font-size: 18px
        line-height: 22px

        &--holiday
            margin-top: 0

        &--kp
            margin-top: 20px

            &::before
                width: 34px
                height: 34px

        &--summer24
            margin-top: 160px

    .hero__content-image
        // position: static
        width: auto
        height: auto
        top: auto
        left: 0
        bottom: 0
        z-index: -1
        display: none

        &--price
            bottom: auto

    .hero__content-pic
        display: block
        width: 100%
        height: 100%
        object-fit: scale-down
        // display: none

    .hero__list
        display: none

    .hero__sale
        right: 0
        top: 40%
        width: 100px

    .hero-calculation-form__label
        width: 100%
        margin-top: 270px

        input
            font-size: 16px

    .hero-calculation-form__btn
        font-size: 16px
        width: auto
        padding: 0 5px

    // form
    .section__form--kp
        top: -345px
        margin-bottom: -345px

    .section__form
        .container
            padding: 0

    .section__form-wrapper:not(.section__form-wrapper--kp)
        padding: 0
        padding-top: 20px
        background-color: $gray8
        border: none
        
        .form
            width: 100%
            padding: 15px 10px 30px 10px
            background-color: white
            border-radius: 3px
            border: 1px solid $light-gray
            .presale__form-label
                width: 100%
                margin-bottom: 15px
            .presale__form-btn
                margin: 0 auto
                margin-top: 0
        .crmresult
            padding: 75px 25px
            background-position: center

    .section__form-wrapper--kp
        .form
            .presale__form-label,.presale__form-btn
                width: 90%
    
    .form__agreement
        padding: 0 10px

    .insert__desc
        font-size: 18px

    .form-list
        width: 100%

    .form-list__title
        font-size: 35px

    .form-list__desc
        font-size: 16px

    // seminar
    .seminar__wrapper
       background-position: center bottom
    
    .seminar__date
        margin-bottom: 10px
    
    .seminar__caption
        font-size: 22px
        line-height: 28px

    .seminar__title
        width: auto
        font-size: 28px
        line-height: 38px

    .seminar__desc
        width: auto

    .seminar__link
        width: auto

    // about
    .about
        padding-bottom: 50px
    .about__desc
        width: auto

    .about__access-text
        width: auto
        margin-top: 20px
        font-size: 28px
        line-height: 32px
        
    .about__list
        width: auto
    .about__pic
        max-width: 100%
        object-fit: contain
    
    .about__button
        width: 100%

    // features
    .features__list
        grid-template-columns: repeat(1,310px)
        margin-top: 0
    
    .features__elem-wrapper
        min-height: 120px
        border-bottom: none

    .features__elem
        padding-right: 0
        border: none
        &:nth-child(4)
            border-bottom: none

    // .insert--second
    .insert--second
        .insert
            padding-top: 20px
    
    // advice
    .advice
        flex-direction: column-reverse

    .advice__content
        width: 320px
        margin: 0 auto

    .advice__about-desc
        text-align: left

    .advice__pic
        width: auto
        margin: 0

    .advice__pic-anim
        width: 250px
        img
            max-width: 100%
            object-fit: contain

    .section-order
        margin-top: 40px
        .container
            padding: 0
    
    .order
        margin-bottom: 0
        padding: 40px 25px 51px 25px
        flex-wrap: wrap
        justify-content: center

        &--holiday
            background-position: left bottom
            background-size: cover

    .order__title
        margin: 0
        font-size: 48px
        line-height: 90%

        &--holiday
            font-size: 34px

    .order__desc
        width: auto
        min-width: auto
        padding-bottom: 20px
        font-size: 22px
        line-height: 27px
        text-align: center

    .order-form
        width: 100%
        margin-left: 0
        margin-right: 0
        padding: 0
        background: transparent

        .order-input
            padding: 7px
            border: 1px solid #979797

    .crmresult
        margin-left: 0
        padding: 45px 35px 28px 35px

    // purchase
    
    .purchase-list
        grid-template-columns: repeat(2,1fr)
        column-gap: 15px

    // footer
    .footer__wrapper
        flex-direction: column
        align-items: flex-start
        padding-bottom: 55px

    .footer__content-right
        width: auto

    .footer__rules
        flex-direction: column

    .private__policy
        margin: 5px 0

    .footer__logo
        margin-top: 40px


@media screen and ( max-width:  430px)
    .hero--kp
        background: url('../img/hero-bg-kp-m.png'), radial-gradient(92.13% 90.13% at 50% 50%, #FF395E 0%, #BA000F 100%)
        background-repeat: no-repeat
        background-size: cover
        background-position: bottom

    .hero--holiday
        background-position: center 300px
        background-size: 70%

    .hero--ndfl
        background-position: center bottom
        background-size: 80%

    .hero__content--kp
        margin: 0
        // padding: 0
        padding-top: 0
        padding-bottom: 430px

    .hero__header-link
        width: 140px
    
    // .hero__title--kp
    //     font-size: 39px
    //     line-height: 34px

    //     span
    //         font-size: 48px
    //         line-height: 44px

    .hero__title--holiday
        font-size: 30px 

    .hero__desc
        &--kp
            width: auto
            font-size: 18px
            line-height: 24px
            margin-top: 20px

        &--holiday
            width: 350px

    .order__desc
        text-align: left

    .hero__header-phone
        display: none
    
    .hero__content-image
        height: auto
        bottom: 85px
        display: none

    // .hero__button--kp
    //     margin-top: 280px

    .promo__note
        width: auto
        font-size: 10px

    .hero__button--holiday
        margin-top: 250px
    
    .hero__present
        width: auto

    .footer__copyright span
        display: block

    .footer__logo
        margin-left: revert

    .purchase-list
        grid-template-columns: auto
        column-gap: 15px

    .id2-rx-noauth-informer-button, .id2-rx-noauth-informer-button span
        font-size: 0

    .id2-rx-user-informer-block a.support-icon:hover
        background-position: center bottom
            
    .informer-wrapper .id2-rx-user-informer-block a.support-icon
        background-size: cover
        display: none

    .informer-wrapper .id2-rx-user-informer-block .id2-rx-user-informer-button
        font-size: 0 !important
        padding: 8px 0px 8px 45px