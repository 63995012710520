.birthday 
  position: relative
  padding: 63px 0 84px
  background: url('../img/birthday-bg.png') no-repeat center, linear-gradient(180deg, rgba(95, 94, 119, 0) 0.01%, rgba(121, 119, 217, 0.9) 48.96%, rgba(82, 82, 101, 0.192857) 72.4%, rgba(36, 36, 36, 0.9) 94.79%)
  color: $white
  background-size: cover
  background-attachment: fixed
  background-blend-mode: multiply
  display: none


.birthday__wrapper
  position: relative
  z-index: 1

  &:before
    content: ''
    position: absolute
    top: 0
    right: 0
    width: 420px
    height: 540px
    background: url('../img/birthday-bg-map.png')
    z-index: -1

.birthday__logo
  width: 119px
  height: 109px

.birthday__title  
  width: 430px
  margin-top: 20px
  font-size: 45px
  font-weight: 600
  line-height: 58px

.birthday__desc
  width: 680px
  font-size: 22px
  line-height: 27px

  span
    display: block
    margin-top: 20px
    font-weight: 600

.birthday__link
  display: flex
  justify-content: center
  align-items: center
  width: 425px
  height: 60px
  margin-top: 35px
  font-size: 22px
  color: $white
  background: #F21C1C
  border-radius: 3px
  transition: 0.3s
  
  &:hover
    background-color: #ff2a2a


@media (max-width: 767px)
  .birthday
    position: relative
    padding-bottom: 30px

    &:before
      content: ''
      position: absolute
      top: 0
      right: 0
      width: 100%
      height: 100%
      background: url('../img/birthday-bg-map.png') no-repeat top right

  .birthday__wrapper
    &:before
      display: none

  .birthday__title
    width: 100%
    margin: 0
    margin-top: 25px
    font-size: 36px
    line-height: 46px
  
  .birthday__desc
    width: 100%
    margin-top: 20px
  
  .birthday__link
    width: 100%