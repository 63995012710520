/* Fonts */
@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 300
  src: local("ProximaNova-Light"), local("Proxima Nova Light"), url("../fonts/ProximaNova-Light.woff2") format("woff2")

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 400
  src: local("ProximaNova-Regular"), local("Proxima Nova Regular"), url("../fonts/ProximaNova-Regular.woff2") format("woff2")

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 600
  src: local("ProximaNova-Semibold"), local("Proxima Nova Semibold"), url("../fonts/ProximaNova-Semibold.woff2") format("woff2")

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 700
  src: local("ProximaNova-Bold"), local("Proxima Nova Bold"), url("../fonts/ProximaNova-Bold.woff2") format("woff2")

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 800
  src: local("ProximaNova-Extrabld"), local("Proxima Nova Extrabld"), url("../fonts/ProximaNova-Extrabld.woff2") format("woff2")

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 900
  src: local("ProximaNova-Black"), local("Proxima Nova Black"), url("../fonts/ProximaNova-Black.woff2") format("woff2")

@font-face 
  font-family: "Bureausans"
  font-style: normal
  font-weight: 600
  src: local('Bureausans'), url('../fonts/Bureausans-Bold.woff2') format('woff2')

/*  */

//  color
$white: #ffffff
$light-gray: #E6E6E6  
$gray1: #e9e7e6
$gray2: #435466
$gray3: #d6d6d6
$gray4: #d8d8d8
$gray5: #a29e9c
$gray6: #6e7992
$gray7: #c3bdba
$gray8: #f6f6f6
$gray9: #424040
$dark-gray: #474D5E

$red: #e50000
$dark-red: #d0021b

$light-blue: #0075ff
$blue: #0278ff

// 
* 
  box-sizing: border-box

html 
  min-width: 100%
  min-height: 100%
  scroll-behavior: smooth

body 
  font-family: 'ProximaNova' 
  width: 100%
  min-width: 375px
  margin: 0
  padding: 0

ul 
  margin: 0
  padding: 0

li 
  list-style-type: none

p, input
  padding: 0
  margin: 0
  font-family: inherit

a 
  color: #000
  text-decoration: none
  display: block

button
  font-family: inherit
  cursor: pointer
  border: none
  background-color: transparent

.main
  position: relative
  width: 100%
  background-color: #f6f6f6
  font-weight: normal

.container
  width: 100%
  height: 100%
  max-width: 1130px
  margin: 0 auto
  display: flex
  flex-direction: column

.visually-hidden 
  position: absolute
  width: 1px
  height: 1px
  margin: 1px
  border: 0
  padding: 0
  clip: rect(0 0 0 0)
  overflow: hidden

.informer-wrapper .id2-rx-user-informer-block 
  display: flex

.informer-wrapper .id2-rx-user-informer-block .id2-rx-user-informer-button 
  background-color: #6e7992
  padding: 8px 12px 8px 40px
  font-size: 13px !important
  color: #d6d6d6
  border-width: inherit
  border-radius: 2px
  background-image: none
  border-color: transparent
  height: auto

.informer-wrapper .id2-rx-user-informer-block .id2-rx-noauth-informer-button .id2-rx-user-informer-icon 
  top: 50% !important
  transform: translateY(-50%) !important
  background: url('../../img/action-logo-w.svg') no-repeat center !important

.informer-wrapper .id2-rx-user-informer-block a.support-icon 
  align-self: flex-end

.id2-rx-user-informer-icon 
  top: 50% !important
  transform: translateY(-50%) !important
  background: url('../../img/action-logo-w.svg') no-repeat center !important
