.offers
  position: relative
  top: -227px
  margin-bottom: -227px
  display: none

.offers__wrapper
  padding: 55px 60px
  background-color: #ffffff
  border-radius: 31px

  @media screen and ( max-width:  767px)
    padding: 40px 10px

.offers__calculation-text
  font-size: 37px
  line-height: 45px
  // display: none

.offers__calculation-result
  font-size: 39px
  display: none

  span
    font-weight: 700
    color: #EE001D
  
.offers__result-title
  font-size: 30px
  font-weight: 700
  display: none

.offers__list
  display: grid
  grid-template-columns: repeat(3,1fr)

  @media screen and ( max-width:  767px)
    grid-template-columns: repeat(1,1fr)

.offer__item
  position: relative
  margin: 0
  margin-left: 34px
  padding-top: 31px
  padding-right: 31px
  font-size: 21px
  line-height: 23px
  border-right: 1px solid #D1D1D1
    
  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 27px
    height: 27px
    background: no-repeat center
    background-size: contain

  &:nth-child(1)
    &::before
      background-image: url('../img/icon/offer/icon-offer-recommendation.svg')

  &:nth-child(2)
    &::before
      background-image: url('../img/icon/offer/icon-offer-unic.svg')

  &:nth-child(3)
    &::before
      background-image: url('../img/icon/offer/icon-offer-base.svg')

  &:nth-child(4)
    &::before
      background-image: url('../img/icon/offer/icon-offer-instruction.svg')

  &:nth-child(5)
    &::before
      background-image: url('../img/icon/offer/icon-offer-navigation.svg')

  &:nth-child(6)
    &::before
      background-image: url('../img/icon/offer/icon-offer-useful.svg')

  &:first-of-type,
  &:nth-child(4)
    margin-left: 0

  &:nth-child(n + 4)
    padding-top: 60px

    &::before
      top: 28px

  &:nth-child(3n)
    border-right: none

  @media screen and ( max-width:  767px)
    margin-left: 0
    margin-bottom: 20px
    border-right: 0

    &:nth-child(n + 4)
      padding-top: 30px

      &::before
        top: 0

.offer__price
  display: block
  margin-top: 10px
  font-size: 15px
  line-height: 15px
  color: #707070
  
.offer__note
  margin-top: 50px
  font-size: 22px
  font-weight: 400
  
.offers__link
  display: flex
  justify-content: center
  align-items: center
  width: 420px
  height: 50px
  margin: 73px auto 0
  font-size: 21px
  color: #ffffff
  background-color: $dark-red
  border-radius: 3px
  transition: .3s

  @media screen and ( max-width:  767px)
    width: auto
    margin-top: 35px
    font-size: 18px